.contentInput {
  padding: 20;

  display: flex;
  justify-content: space-between;
  margin-bottom: 8;
  font-family: "Roboto";
  font-size: 20;
}
.contentInput1 {
  padding: 20;

  display: flex;
  justify-content: space-between;
  margin-bottom: 8;
}
.rdrDateRangePickerWrapper{
  display: flex;
  justify-content: flex-end;
}
.form-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* background-color: brown; */
  width: 100%;
}
.checkbox {
  width: 100%;
  /* margin-bottom: 8px; */
}
.checkbox label {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.checkbox input[type="checkbox"] {
  height: 20px;
  width: 20px;
}
fieldset {
  border-style: none;
  font-family: "Roboto";
  font-size: 16px;
}

.form-group * {
  margin-bottom: 4px;
  /* height: 100px; */
}

.form-group * {
  margin-bottom: 4px;
  /* height: 100px; */
}
.form-group button {
  text-align: center;
  /* height: 100px; */
}
.form-control {
  padding: 8px;
  border-radius: 4px;
  border-width: 1px;
}
.MuiDialog-paperWidthSm {
  width: "100%" !important;
  max-width: unset;
}
#root__title {
  font-weight: bold;
  font-size: 20px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}
.rjsf {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
